import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
//import svg from "./../../assets/images/6661-01.svg";

const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Build up the whole picture",
    paragraph:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container_about_us">
        <div className="cont_text">
          <div className="title_text">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              About Us
            </h1>
          </div>
          <div className="text">
            <h3
              className="m-0 mb-32 reveal-from-bottom"
              data-reveal-delay="400"
            >
              We are a Startup that provide solutions for business, we analyze
              data and develop strategies to help you grow your business. We can
              implement solutions from software to hardware, we believe that
              with programming everything is possible.
            </h3>
          </div>
        </div>
        <div className="cont_image">
          <div className="img"> </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesTiles;
