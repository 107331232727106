import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";

const items = [
  {
    image:
      "https://cdn.discordapp.com/attachments/1070351620912726066/1070787265086890044/DeFi-in-Crypto-image-4.jpg",
    title: "Item 1",
    description: "This is the description for Item 1",
  },
  {
    image:
      "https://cdn.discordapp.com/attachments/1070351620912726066/1070787265086890044/DeFi-in-Crypto-image-4.jpg",
    title: "Item 2",
    description: "This is the description for Item 2",
  },
  {
    image:
      "https://cdn.discordapp.com/attachments/1070351620912726066/1070787265086890044/DeFi-in-Crypto-image-4.jpg",
    title: "Item 3",
    description: "This is the description for Item 3",
  },
  {
    image:
      "https://cdn.discordapp.com/attachments/1070351620912726066/1070787265086890044/DeFi-in-Crypto-image-4.jpg",
    title: "Item 1",
    description: "This is the description for Item 1",
  },
  {
    image:
      "https://cdn.discordapp.com/attachments/1070351620912726066/1070787265086890044/DeFi-in-Crypto-image-4.jpg",
    title: "Item 2",
    description: "This is the description for Item 2",
  },
  {
    image:
      "https://cdn.discordapp.com/attachments/1070351620912726066/1070787265086890044/DeFi-in-Crypto-image-4.jpg",
    title: "Item 3",
    description: "This is the description for Item 3",
  },
];

const Home = () => {
  return (
    <>
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <Hero />
      <FeaturesTiles />
      <Testimonial />
      <Cta split />
    </>
  );
};

export default Home;
